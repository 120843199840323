/* DatePicker Container */
.ui-datepicker {
	width: auto;
	height: auto;
	margin: 5px auto 0;
	font: 9pt Arial, sans-serif;
	-webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5);
	-moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5);
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .5);
	background: white;
    display: none;
}
.ui-datepicker a {
	text-decoration: none;
}
/* DatePicker Table */
.ui-datepicker table {
	width: 100%;
}
.ui-datepicker-header {
	background: #828282;
	color: #e0e0e0;
	line-height: 30px;
}
.ui-datepicker-title {
	text-align: center;
	padding: 15px;
	font-size: 18px;
}
.ui-datepicker-prev, .ui-datepicker-next {
	display: inline-block;
	width: 50px;
	height: 60px;
	line-height: 60px;
	text-align: center;
	cursor: pointer;
	overflow: hidden;
	position: relative;
	color: transparent!important;
}
.ui-datepicker-prev:before, .ui-datepicker-next:before {
    position:absolute;
    content:"";
    display:inline-block;
    font-family: 'LinearIcons';
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-weight: normal;
    font-stretch: normal;
    text-transform: none;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100%;
    color: white;
    font-size: 15px;
    left: 0;
}
.ui-datepicker-prev:before {
    content: "\e875";
}
.ui-datepicker-next:before {
    content: "\e876";
}
.ui-datepicker-prev {
	float: left;
	background-position: center -30px;
}
.ui-datepicker-next {
	float: right;
	background-position: center 0px;
}
.ui-datepicker thead {
	background-color: #f7f7f7;
	background-image: -moz-linear-gradient(top,  #f7f7f7 0%, #f1f1f1 100%);
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#f7f7f7), color-stop(100%,#f1f1f1));
	background-image: -webkit-linear-gradient(top,  #f7f7f7 0%,#f1f1f1 100%);
	background-image: -o-linear-gradient(top,  #f7f7f7 0%,#f1f1f1 100%);
	background-image: -ms-linear-gradient(top,  #f7f7f7 0%,#f1f1f1 100%);
	background-image: linear-gradient(top,  #f7f7f7 0%,#f1f1f1 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#f1f1f1',GradientType=0 );
	border-bottom: 1px solid #bbb;
}
.ui-datepicker th {
	text-transform: uppercase;
	font-size: 6pt;
	padding: 5px 0;
	color: #666666;
	text-shadow: 1px 0px 0px #fff;
	filter: dropshadow(color=#fff, offx=1, offy=0);
	text-align: center;
}
.ui-datepicker tbody td {
	padding: 0px;
	border-right: 1px solid #bbb;
}
.ui-datepicker tbody td:last-child {
	border-right: 0px;
}
.ui-datepicker tbody tr {
	border-bottom: 1px solid #bbb;
}
.ui-datepicker tbody tr:last-child {
	border-bottom: 0px;
}
.ui-datepicker td span, .ui-datepicker td a {
	display: block;
	font-weight: bold;
	text-align: center;
	line-height: 30px;
	color: #666666;
	filter: dropshadow(color=#fff, offx=1, offy=1);
	padding: 15px 20px;
}
.ui-datepicker-calendar .ui-state-default {
	background: #efefef;
}
.ui-datepicker-calendar .ui-state-hover {
	background: #f7f7f7;
}
.ui-datepicker-calendar .ui-state-active {
	color: #e0e0e0;
	position: relative;
	margin: -1px;
}
.ui-datepicker-unselectable .ui-state-default {
	background: #f4f4f4;
	color: #b4b3b3;
}
.ui-datepicker-calendar td:first-child .ui-state-active {
	margin-left: -1px;
}
.ui-datepicker-calendar td:last-child .ui-state-active {
	margin-right: -1px;
}
.ui-datepicker-calendar tr:last-child .ui-state-active {
	margin-bottom: -1px;
}
